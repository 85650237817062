import { React, useState } from 'react';
import { BiDownArrow } from "react-icons/bi";
import { Button } from 'reactstrap'
import SandBG from '../images/sand-bg.png';
import VillaArthurLogo from '../images/villa-arthur-logo.png';
import VillaComplexLogo from '../images/villa-complex-logo.png';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import c0 from '../images/complex/c-0.jpg'
import c1 from '../images/complex/c-1.jpg'
import c2 from '../images/complex/c-2.jpg'
import c3 from '../images/complex/c-3.jpg'
import c4 from '../images/complex/c-4.jpg'
import c5 from '../images/complex/c-5.jpg'
import c6 from '../images/complex/c-6.jpg'
import c8 from '../images/complex/c-8.jpg'
import c9 from '../images/complex/c-9.jpg'
import c10 from '../images/complex/c-10.jpg'
import c11 from '../images/complex/c-11.jpg'
import a1 from '../images/arthur/a-1.webp'
import a2 from '../images/arthur/a-2.webp'
import a3 from '../images/arthur/a-3.webp'
import a4 from '../images/arthur/a-4.webp'
import a5 from '../images/arthur/a-5.webp'
import a6 from '../images/arthur/a-6.webp'
import a7 from '../images/arthur/a-7.webp'
import a8 from '../images/arthur/a-8.webp'
import a9 from '../images/arthur/a-9.webp'
import a10 from '../images/arthur/a-10.webp'
import a11 from '../images/arthur/a-11.webp'
import useViewportHeight from '../useViewportHeight';

const complexImages = [c0, c1, c2, c3, c4, c5, c6, c8, c9, c10, c11];
const arthurImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11];



function Villas() {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [activeImages, setActiveImages] = useState([]);
    const [mapView, setMapView] = useState(false)
    const vh = useViewportHeight();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1500,
    };

    const openLightbox = (images, index) => {
        setIsOpen(true);
        setPhotoIndex(index);
        setActiveImages(images);
    };

    return (
        <div
            className="custom-viewport-height"
            style={{
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                background: `url(${SandBG}) no-repeat center center`,
                backgroundSize: 'cover', // Cover the entire div
                position: 'relative' // Add this for the down arrow
            }}
        >
            <h3 style={{ color: 'black' }}>Villas</h3>
            <div>
                <Button onClick={()=>{setMapView(false)}}className="rounded-pill bg-black text-white reg-text">Properties</Button>
                <Button onClick={()=>{setMapView(true)}}className="rounded-pill bg-black text-white reg-text">Map</Button>
            </div>


            
            {
                mapView ?
                <>
                <br/>
                 <div style={{height:'70vh'}}dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d936.0937688051072!2d-87.47452593036103!3d20.201729207431686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m5!1s0x8f4fd77cfa5a8c2b%3A0xc6b965c650ef7fb1!2sVilla%20Arthur%20900%2C%20C.%2010%20Sur%20731-11%2C%20La%20Veleta%2C%2077760%20Tulum%2C%20Q.R.%2C%20Mexico!3m2!1d20.201613899999998!2d-87.47495099999999!4m3!3m2!1d20.2007446!2d-87.4751434!5e0!3m2!1sen!2sus!4v1694659920941!5m2!1sen!2sus" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' }}></div>
                </>:
                <>
                <div className="text-left">
                <img src={VillaComplexLogo} alt="Invitation" style={{ width: '50%' }} />
                <div className="pl-2">
                    <Slider {...settings}>
                        {complexImages.map((image, index) => (
                            <div key={index} onClick={() => openLightbox(complexImages, index)}>
                                <img src={image} alt="" style={{ maxHeight: '25vh', width: 'auto' }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <img src={VillaArthurLogo} alt="Invitation" style={{ width: '50%' }} />
                <div className="pl-2">
                    <Slider {...settings}>
                        {arthurImages.map((image, index) => (
                            <div key={index} onClick={() => openLightbox(arthurImages, index)}>
                                <img src={image} alt="" style={{ maxHeight: '25vh', width: 'auto' }} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
                </>
            }
           




            {isOpen && (
                <Lightbox
                    mainSrc={activeImages[photoIndex]}
                    nextSrc={activeImages[(photoIndex + 1) % activeImages.length]}
                    prevSrc={activeImages[(photoIndex + activeImages.length - 1) % activeImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + activeImages.length - 1) % activeImages.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % activeImages.length)}
                />
            )}
            <div style={{
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                {/* <small style={{ fontFamily: 'Libre', color: 'black' }}>Confirm</small><br /> */}
                <BiDownArrow size={30} color="black" />
            </div>
        </div>
    );
}

export default Villas;
