import React, { useEffect } from 'react';
import { BiDownArrow } from "react-icons/bi";
import Welcome from '../images/welcome.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import useViewportHeight from '../useViewportHeight';
import Name from '../images/NahidKamal.png';
AOS.init();


function WelcomeSection() {
  useViewportHeight();

  return (
    <div
      className="custom-viewport-height"
      style={{
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: `url(${Welcome}) no-repeat center center`,
        backgroundSize: 'cover',
        position: 'relative'
      }}
    >
      {/* <p className="reg-text">Welcome to</p> */}
      <h1 data-aos="zoom-out" data-aos-duration="1500">The Tulum Escape</h1>
      <p className="reg-text" data-aos="fade-in" data-aos-duration="2500">by</p>
      <img data-aos="fade-in" data-aos-duration="2500" src={Name}></img>
      <br />
      <br />
      <br />
      <br />
      <div data-aos="fade-in" data-aos-duration="3000">
        <p className="reg-text">Nov 30 - Dec 4</p>
      </div>
      <div style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        <BiDownArrow size={30} color="white" />
      </div>
    </div>
  );
}

export default WelcomeSection;
