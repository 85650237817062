import { useEffect } from 'react';

export default function useViewportHeight() {
  useEffect(() => {
    // Initialize the --vh variable
    const setInitialVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setInitialVH();  // Set the initial value

    // Update the --vh variable on resize
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // No longer returning 'vh' since we're using a CSS variable
}
