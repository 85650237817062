import React, { useState } from 'react';
import axios from 'axios';
import InviteBG from '../images/invite-bg.png';
import { Input } from 'reactstrap';
import useViewportHeight from '../useViewportHeight';

const inputStyle = {
  WebkitAppearance: 'none',
  borderRadius: '50px',
  borderColor: 'black',
  backgroundColor: 'black',
  color: 'white',
  verticalAlign: 'middle',
  fontFamily: 'Libre',
  border: '1px solid',
  width: '100%',
  colorScheme: 'dark'
};

const labelStyle = {
  fontFamily: "Libre",
  color: 'white'
};

const buttonStyle = {
  borderRadius: '50px',
  borderColor: 'black',
  backgroundColor: 'black',
  color: 'white',
  border: '1px solid'
};

const circleButtonStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  borderColor: 'black',
  backgroundColor: 'black',
  color: 'white',
  border: '1px solid',
  textAlign: 'center',
  lineHeight: '35px',
  display: 'inline-block'
}


function Confirm() {
  const [names, setNames] = useState(['']);
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');
  const [success, setSuccess] = useState(false)
  const vh = useViewportHeight();

  const addNameField = () => {
    setNames([...names, '']);
  };

  const updateName = (index, value) => {
    const newNames = [...names];
    newNames[index] = value;
    setNames(newNames);
  };

  const handleSubmit = async () => {

    const postData = {
      user: String(names.join(',')),
      arrival_date: arrivalDate,
      arrival_time: arrivalTime,
    };
    console.log(postData)

    await axios.post('https://hook.us1.make.com/x5wg9tp95xp7vbq9q8duu3tr7pr9dtco', postData).then(value => {
      setSuccess(true)
    })
  };
  return (
    <div
    className="custom-viewport-height"
      style={{
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: `url(${InviteBG}) no-repeat center center`,
        backgroundSize: 'cover', // Cover the entire div
        position: 'relative' // Add this for the down arrow
      }}
    >
      {
        success ?
          <>
          <h1>All set.</h1>
          <small className="reg-text">
              See you in Tulum. We'll be in touch with more details soon.
              <br /><br />
            </small>
          </> :
          <>
            <h1>Confirm</h1>
            <small className="reg-text">
              Send us your flight details soon. We can only host 40 people across our villas!
              <br /><br />
            </small>
            <div className="text-left">
              {names.map((name, index) => (
                <div key={index}>
                  <small><small style={{ fontFamily: "Libre", color: 'white' }}>Name(s)</small></small>
                  <br />
                  <input
                    style={{
                      borderRadius: '50px',
                      borderColor: 'black',
                      backgroundColor: 'black',
                      color: 'white',
                      fontFamily: 'The Seasons',
                      border: '1px solid',
                      width: '70%'
                    }}
                    placeholder="Name"
                    className="px-3 py-2"
                    value={name}
                    onChange={(e) => updateName(index, e.target.value)}
                  />
                  <button className="mx-3" onClick={addNameField} style={circleButtonStyle}>+</button>
                </div>
              ))}



              <br /><br />
              <small><small style={{ fontFamily: "Libre", color: 'white' }}>Arrival Date</small></small>
              <br />
              <Input
                type="date"
                style={inputStyle}
                value={arrivalDate}
                // className="px-3 py-2"
                onChange={(e) => setArrivalDate(e.target.value)}
              />

              <br /><br />
              <small><small style={{ fontFamily: "Libre", color: 'white' }}>Arrival Time</small></small>
              <br />
              <Input
                type="time"
                style={inputStyle}
                value={arrivalTime}
                className="px-3 py-2"
                onChange={(e) => setArrivalTime(e.target.value)}
              />

              <br /><br />
              <div className="text-center">
                <button className="px-5 py-2 reg-text" onClick={handleSubmit} style={buttonStyle}>Submit</button>
              </div>
            </div>
          </>
      }



      <div style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        <small className="reg-text">See you there.</small><br />
      </div>
    </div>
  );
}

export default Confirm;
