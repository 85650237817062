import { React, useState, useEffect } from 'react';
import { BiDownArrow } from "react-icons/bi";
import { GiForkKnifeSpoon } from 'react-icons/gi';
import { FaGlassMartiniAlt } from 'react-icons/fa';
import { PiVinylRecordFill } from 'react-icons/pi';
import { MdDirectionsBoat } from 'react-icons/md';
import { FaPersonSwimming } from 'react-icons/fa6';
import { GiPalmTree } from 'react-icons/gi';
import { BiSolidMusic } from 'react-icons/bi';
import { BsFillMoonStarsFill } from 'react-icons/bs';
import { BsFillSunFill } from 'react-icons/bs';
import Wood from '../images/wood.png';
import ArrivalDinner from '../images/arrival-bg.png';
import Yatch from '../images/yatch-bg.png';
import Club from '../images/club-bg.png';
import Image1 from '../images/1.png';
import Image2 from '../images/2.png';
import Image3 from '../images/3.png';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import useViewportHeight from '../useViewportHeight';
import { SiGooglemaps } from "react-icons/si";

import Recovery from '../images/recovery-bg.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Events() {
  const [showNight1, setShowNight1] = useState(false);
  const [showNight2, setShowNight2] = useState(false);
  const [showNight3, setShowNight3] = useState(false);
  const vh = useViewportHeight();

  useEffect(() => {
    const imageUrls = [
      Wood,
      ArrivalDinner,
      Yatch,
      Club,
      Image1,
      Image2,
      Image3,
      Recovery,
    ];
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, []);
  
  if (showNight1) {

    return (
      <>
        <div
        className="custom-viewport-height"
          style={{
            // textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingTop: '40px',
            background: `url(${Wood}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
            position: 'relative' // Add this for the down arrow
          }}
        >
          <BsChevronLeft size={30} color="white" onClick={() => { setShowNight1(false) }} />
          <br />
          <img
            src={Image1}
            style={{
              objectFit: 'cover',
              height: '25vh',
              width: 'auto'
            }}
          />
          <div className="px-3 py-3">
            <small className="text-muted reg-text">
              Thursday, Nov 30 @ 8pm
            </small>
            <h4>Villa Verve: Tulum Arrival Dinner</h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><GiForkKnifeSpoon /> Chef</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><FaGlassMartiniAlt /> Bartender</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><PiVinylRecordFill /> DJ</small>
              </div>
            </div>
            <br />

          </div>
          <div className="text-left px-3 py-2" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td className="text-left">
                  <small><small className="text-muted">Location:</small></small><br />
                  <small className="reg-text">
                    <a href="https://maps.app.goo.gl/STYe7BXoBUH8GA5r8" className="text-white">
                    <SiGooglemaps /> Main Villa
                      </a>                    
                      
                    </small><br /><br />
                </td>
                <td className="text-right">
                  <small><small className="text-muted">Dress Code:</small></small><br />
                  <small className="reg-text">All white</small><br /><br />
                </td>
              </tr>
            </table>
            <small><small className="text-muted">Event:</small></small><br />
            <small className="reg-text">Let's gather together after a day of travel to relax, catch up, and vibe. We'll have a private chef preparing dinner with our own DJ and Bartender on deck.</small><br /><br />


          </div>
        </div>
      </>
    )
  }
  if (showNight2) {
    return (
      <>
        <div
        className="custom-viewport-height"
          style={{
            // textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingTop: '40px',
            background: `url(${Wood}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
            position: 'relative' // Add this for the down arrow
          }}
        >
          <BsChevronLeft size={30} color="white" onClick={() => { setShowNight2(false) }} />
          <br />
          <img
            src={Image2}
            style={{
              objectFit: 'cover',
              height: '25vh',
              width: 'auto'
            }}
          />
          <div className="px-3 py-3">
            <small className="text-muted reg-text">
              Friday, Dec 1 @ 12pm 
            </small>
            <h4 style={{ color: 'white', fontFamily: 'Libre' }}>Turquoise Thrills: Yacht Party</h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><FaPersonSwimming /> Swimming</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><FaGlassMartiniAlt /> Drinks</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><BsFillSunFill /> Daytime</small>
              </div>
            </div>
            <br />

          </div>
          <div className="text-left px-3 py-2" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td className="text-left">
                  <small><small className="text-muted">Location:</small></small><br />
                  <small className="reg-text">The ocean</small><br /><br />
                </td>
                <td className="text-right">
                  <small><small className="text-muted">Dress Code:</small></small><br />
                  <small className="reg-text">Swimwear</small><br /><br />
                </td>
              </tr>
            </table>
            <small><small className="text-muted">Event:</small></small><br />
            <small className="reg-text">
              Private captain, open bar, sunshine, and turquoise waters. We'll be cruising the Caribbean Sea on a private yacht for the day. We'll have food and plenty of drinks to keep the party going.
              <br/><br/>
              Please arrive at the Main Villa by 12pm. We'll be driven to the port ~40 minutes away.
            </small><br /><br />
          </div>
        </div>
      </>
    )
  }
  if (showNight3) {

    return (
      <>
        <div
        className="custom-viewport-height"
          style={{
            // textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingTop: '40px',
            background: `url(${Wood}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
            position: 'relative' // Add this for the down arrow
          }}
        >
          <BsChevronLeft size={30} color="white" onClick={() => { setShowNight3(false) }} />
          <br />
          <img
            src={Image3}
            style={{
              objectFit: 'cover',
              height: '25vh',
              width: 'auto'
            }}
          />
          <div className="px-3 py-3">
            <small className="reg-text">
              Saturday, Dec 2 @ night
            </small>
            <h4>Aztec Rhythm: Tulum Tech Night</h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><GiPalmTree /> Clubs</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><BiSolidMusic /> Dancing</small>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small style={{ color: '#FFF0DB' }}><BsFillMoonStarsFill /> Night</small>
              </div>
            </div>
            <br />

          </div>
          <div className="text-left px-3 py-2" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td className="text-left">
                  <small><small className="text-muted">Location:</small></small><br />
                  <small className="reg-text">Many</small><br /><br />
                </td>
                <td className="text-right">
                  <small><small className="text-muted">Dress Code:</small></small><br />
                  <small className="reg-text">Boho-Chic</small><br /><br />
                </td>
              </tr>
            </table>
            <small><small className="text-muted">Event:</small></small><br />
            <small className="reg-text">
              We'll start off the night at the most popular clubs along the beach and make our way through the nightlife scene towards the city.
            </small><br /><br />


          </div>
        </div>
      </>
    )
  }


  return (
    <div
    className="custom-viewport-height"
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: `url(${Wood}) no-repeat center center`,
        backgroundSize: 'cover', // Cover the entire div
        position: 'relative' // Add this for the down arrow
      }}
    >
      <br /><br />
      <div className="pt-4">
        <h3>Events</h3>
        <small className="reg-text">
          <small>
            Celebrating birthdays in 3 different ways.
          </small>
        </small>
      </div>
      <br />
      <div className="text-left">
        <div
          onClick={() => { setShowNight1(true) }}
          style={{
            padding: '20px',
            background: `url(${ArrivalDinner}) no-repeat center center`,
            backgroundSize: 'cover'
          }}>
          <small className="reg-text">
            Thursday, Nov 30 @ 8pm
          </small>
          <h4>Villa Verve: Tulum Arrival Dinner</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><GiForkKnifeSpoon /> Chef</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><FaGlassMartiniAlt /> Bartender</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><PiVinylRecordFill /> DJ</small>
            </div>
          </div>

        </div>
        <br /><br />

        <div
          onClick={() => { setShowNight2(true) }}
          style={{
            padding: '20px',
            background: `url(${Yatch}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
          }}>
          <small style={{ color: 'white', fontFamily: 'Libre' }}>
            Friday, Dec 1 @ 12pm
          </small>
          <h4 style={{ color: 'white', fontFamily: 'Libre' }}>Turquoise Thrills: Yacht Party</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><FaPersonSwimming /> Swimming</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><FaGlassMartiniAlt /> Drinks</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><BsFillSunFill /> Daytime</small>
            </div>
          </div>
        </div>
        <br /><br />

        <div
          onClick={() => { setShowNight3(true) }}
          style={{
            padding: '20px',
            background: `url(${Club}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
          }}>
          <small className="reg-text">
            Saturday, Dec 2 @ night
          </small>
          <h4>Aztec Rhythm: Tulum Tech Night</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><GiPalmTree /> Clubs</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><BiSolidMusic /> Dancing</small>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <small style={{ color: '#FFF0DB' }}><BsFillMoonStarsFill /> Night</small>
            </div>
          </div>
        </div>
        <br /><br />
        {/* <div
          style={{
            padding: '20px',
            background: `url(${Recovery}) no-repeat center center`,
            backgroundSize: 'cover', // Cover the entire div
          }}>
         <small style={{color:'white', fontFamily:'Libre'}}>
            Sunday, Dec 3
          </small>
          <h3 style={{color:'white', fontFamily:'Libre'}}>Rest and Recovery</h3>
        </div> */}
        <br /><br />

        <br /><br />
      </div>



      <div style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        {/* <small className="reg-text">Villas</small><br /> */}
        <BiDownArrow size={30} color="white" />
      </div>
    </div>
  );
}

export default Events;
