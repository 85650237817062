import { React, useEffect, useState, useRef } from 'react';
import { Container } from 'reactstrap';
import WelcomeSection from './components/WelcomeSection';
import YourInvitation from './components/YourInvitation';
import Villas from './components/Villas';
import Events from './components/Events';
import Confirm from './components/Confirm';
import Wood from './images/wood.png';
import QR from './images/qr-code.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { validPasscodes } from './passcodes';
import useViewportHeight from './useViewportHeight';

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function App() {
  const [accessGranted, setAccessGranted] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [dismissKeyboard, setDismissKeyboard] = useState(false);

  useViewportHeight();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.endsWith('/events')) {
      const eventsSection = document.getElementById('events');
      if (eventsSection) {
        eventsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [updater]);

  useEffect(() => {
    // Listener for user interaction
    const handleFirstInteraction = () => {
      const audioEl = document.getElementById('audioElement');
      if (audioEl) {
        audioEl.volume = 0.2;
        audioEl.muted = false;
        audioEl.play();
      }
      // Remove the event listener once it's done
      window.removeEventListener('click', handleFirstInteraction);
    };

    window.addEventListener('click', handleFirstInteraction);
  }, []);

  const checkPasscode = () => {
    const userCode = prompt('Enter the passcode:');
    if (validPasscodes.includes(userCode)) {
      setAccessGranted(true);
      localStorage.setItem('access', 'granted');
    }
  };

  if (!isMobile()) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
      }}>

        <p style={{
          fontFamily: 'The Seasons',
          color: '#cde',
          fontSize: '24px',
        }}>
          View on mobile.
        </p>
        <img src={QR} alt="QR Code" style={{ width: '50%' }} />
      </div>

    );
  }
  return (
    <Container className="scrolling-container p-0" style={{ overflowX: 'hidden' }}>
      <audio id="audioElement" loop>
        <source src="./YaKnow.mp3" type="audio/mp3" />
      </audio>
      <div className="snap-section">
        <WelcomeSection />
      </div>
      <div className="snap-section">
        <YourInvitation />
      </div>
      <div id="events" className="snap-section">
        <Events />
      </div>
      <div className="snap-section">
        <Villas />
      </div>
      <div className="snap-section">
        <Confirm />
      </div>
    </Container>
  );
  
}

export default App;