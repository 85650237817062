import React from 'react';
import { BiDownArrow } from "react-icons/bi";
import Invite from '../images/invite.png';
import InviteBG from '../images/invite-bg.png';
import useViewportHeight from '../useViewportHeight';

function YourInvitation() {
  const vh = useViewportHeight();
  return (
    <div
    className="custom-viewport-height"
      style={{
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: `url(${InviteBG}) no-repeat center center`,
        backgroundSize: 'cover', // Cover the entire div
        position: 'relative' // Add this for the down arrow
      }}
    >
      <h1>Your Invitation</h1>
      <small className="reg-text">
        Your presence is requested for an unforgettable weekend in Tulum, Mexico.
        <br /><br />
      </small>
      <div>
      <img src={Invite} alt="Invitation" style={{ width: '100%' }} />
      </div>


      <div style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        {/* <small className="reg-text">Events</small><br/> */}
        <BiDownArrow size={30} color="white" />
      </div>
    </div>
  );
}

export default YourInvitation;
